import { FC, memo } from 'react';

import { RolesId } from 'app/models/Role';
import Comments from 'core/containers/Comments';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import useAppSelector from 'core/hooks/useAppSelector';

const MissionCommentsTab: FC = () => {
  const { id } = useDetail();
  const user = useAppSelector((state) => state.core.user);

  if (!id) {
    return null;
  }
  return (
    <Comments
      entity="mission"
      entityId={id!}
      blacklistedRoles={[RolesId.DCC]}
      canAddComment={user?.rolesId !== RolesId.VIEWER}
    />
  );
};

export default memo(MissionCommentsTab);

import styled from 'styled-components/macro';

import theme from 'app/theme';
import { hidePrint } from 'core/components/PrintOnly/styled';

export const SideWrap = styled.div.attrs(({ className, children }) => ({ className, children }))<{
  collapse: boolean;
}>`
  background-color: var(--mdc-theme-secondary);
  width: ${({ collapse }) => (collapse ? theme.drawerColapseWidth : theme.drawerWidth)};
  transition: width ease 0.45s;
  color: ${theme.color.white};
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  overflow-x: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  user-select: none;
  ${hidePrint}

  @media print {
    height: auto;
  }
`;

import 'cropperjs/dist/cropper.css';
import { FC, useEffect, useRef, useState } from 'react';
import { ReactCropperProps } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';

import Slider from 'core/components/Slider';

import { StyledCropper, StyledSliderWrapper } from './styled';

interface Props {
  imgFile?: File;
  onCropImg: (data: HTMLCanvasElement) => void;
  cropperProps?: ReactCropperProps;
  width?: number;
  height?: number;
}

const AvatarCropper: FC<Props> = ({ imgFile, onCropImg, cropperProps, width, height }) => {
  const [thumb, setThumb] = useState<string | undefined>();
  const [zoom, setZoom] = useState<number>(0);
  const { t } = useTranslation();
  const cropperRef = useRef<HTMLImageElement & { cropper: Cropper }>(null);
  const [showCorruptedImageToast] = useDebouncedCallback(
    () => {
      toast.warn(t('Looks like your image is corrupted, please select another file') as string);
    },
    5000,
    { leading: true }
  );

  const [callChangeImg] = useDebouncedCallback(() => {
    if (cropperRef && cropperRef.current) {
      const cropper = cropperRef.current.cropper;

      const dataCanvas = cropper.getCroppedCanvas({
        width,
        height,
      });
      onCropImg(dataCanvas);
    }
  }, 500);

  useEffect(() => {
    if (!imgFile) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setThumb((reader.result as string) || undefined);
    };

    reader.readAsDataURL(imgFile);
  }, [imgFile]);

  return (
    <>
      <StyledCropper
        {...cropperProps}
        as={undefined}
        ref={cropperRef}
        src={thumb}
        crop={callChangeImg}
        guides={false}
      />
      <StyledSliderWrapper>
        <Slider
          step={0.001}
          defaultValue={0}
          min={-1}
          max={1}
          value={zoom}
          onChange={(newValue: number) => {
            if (cropperRef && cropperRef.current) {
              const cropper = cropperRef.current.cropper;
              try {
                if (newValue > zoom) {
                  const diff = newValue - zoom;
                  cropper.zoom(diff);
                } else {
                  const diff = zoom - newValue;
                  cropper.zoom(-diff);
                }
                setZoom(newValue);
              } catch (e) {
                showCorruptedImageToast();
              }
            }
          }}
          leftIcon="zoom_out"
          rightIcon="zoom_in"
        />
      </StyledSliderWrapper>
    </>
  );
};

export default AvatarCropper;

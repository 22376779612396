import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormViewMode } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { ExplanationWrapper } from 'core/components/FormStyles';
import FormSwitch from 'core/components/FormSwitch';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import { TestFormData } from 'planning/containers/TestDetailPage/hooks/useTestSchema';
import Test from 'planning/models/Test';

import { TeamMemberType } from './enums';
import MembersCard from './MembersCard';
import useUserAvailabilities from './MembersCard/useUserAvailabilities';
import useAssignedMembers from './useAssignedMembers';
import { TeamMemberData } from './useTeamInputMapping';

const emptyData = [] as TeamMemberData[];

const TeamTab: FC = () => {
  const viewModeActive = !!useFormViewMode();
  const { entityData: initialData } = useDetail<Test, TestFormData>();
  const { t } = useTranslation();

  const invited = useFormFieldValue('invitedMembers') || (emptyData as TeamMemberData[]);
  const assigned = useFormFieldValue('assignedMembers') || (emptyData as TeamMemberData[]);

  const canEditTeam = usePermission('tests:patch[actions:team]');

  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');
  const members = useMemo(() => [...invited, ...assigned], [invited, assigned]);
  const availabilities = useUserAvailabilities(availabilitiesEnabled ? members : []);

  const currentAssignedMembers = useAssignedMembers(initialData?.teamMembers || []);
  const alreadySavedUsersId = useMemo(
    () => (currentAssignedMembers.assignedMembers || []).map((i) => i.usersId),
    [currentAssignedMembers.assignedMembers]
  );

  return (
    <Row>
      <FullRowCell>
        <MembersCard type={TeamMemberType.INVITED} data={invited} availabilities={availabilities} />
      </FullRowCell>

      <FullRowCell>
        <MembersCard
          limitedAssignmentAllowed={viewModeActive && canEditTeam}
          alreadySavedUsersId={alreadySavedUsersId}
          availabilities={availabilities}
          type={TeamMemberType.ASSIGNED}
          data={assigned}
        />
      </FullRowCell>

      <FullRowCell>
        <PaperContainer title={t('Settings')}>
          <Row>
            <FullRowCell>
              <Field
                label={t('Notify me of status updates')}
                name="notifyOfTeamUpdates"
                component={FormSwitch}
                inline
              />

              <ExplanationWrapper>
                {t(
                  'The assignee will be notified by e-mail when invited team members react to the invitation.'
                )}
              </ExplanationWrapper>
            </FullRowCell>

            <FullRowCell>
              <Field
                label={t('Show athlete and gender to invited DCO')}
                name="showAthleteToDco"
                component={FormSwitch}
                inline
              />

              <ExplanationWrapper>
                {t('The invited DCO will be able to see the athlete and gender in the Backoffice.')}
              </ExplanationWrapper>
            </FullRowCell>
          </Row>
        </PaperContainer>
      </FullRowCell>
    </Row>
  );
};

export default TeamTab;

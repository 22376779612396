import { useMemo } from 'react';

import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useResources } from 'core/hooks/useResource';
import { useHumanReadableAvailability } from 'personnel/hooks/useHumanReadableAvailability';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';
import TeamMember from 'planning/models/TeamMember';

const useUserAvailabilities = (users: TeamMember[]) => {
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');
  const { data: availabilityCategories } = useResources<AvailabilityCategory>(
    'profile/availabilities/categories',
    {
      autoload: availabilitiesEnabled,
    }
  );

  const getHumanReadableAvailability = useHumanReadableAvailability(availabilityCategories);
  const availabilities = useMemo(
    () =>
      users?.reduce(
        (acc, user) => {
          acc[user.usersId] = getHumanReadableAvailability(user.availabilities);

          return acc;
        },
        {} as { [usersId: number]: string }
      ) || {},
    [getHumanReadableAvailability, users]
  );

  return availabilities;
};

export default useUserAvailabilities;
